import { useState, useRef, useEffect } from "react";
import BitromService from '../../services/BitromService';
import { BitromContainer } from "./styles";

const ProductForm = () => {
  const [id, setId] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [packaging, setPackaging] = useState("");
  const [price, setPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [barcode, setBarcode] = useState("");
  const [ipi, setIpi] = useState("");
  const [quantityStock, setQuantityStock] = useState("");
  const [file, setFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [packagings, setPackagings] = useState([
    "CAIXA/10", "CAIXA/12", "CAIXA/16", "CAIXA/40", "CARTELA/1", "CARTELA/10", "CARTELA/12", "CARTELA/14", "CARTELA/3", 
    "CARTELA/5", "CARTELA/6", "CARTELA/8", "EMB/1", "EMB/10", "EMB/6", "FARDO/10", "KIT/1", "KIT/12", "KIT/3", "KIT/4", "KIT/5", 
    "PACOTE/10", "PACOTE/20", "PACOTE/4", "PACOTE/50", "PAR/1", "PAR/2", "ROLO/50", "UNID/1", "UNID/4",
  ]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedPackaging, setSelectedPackaging] = useState(""); // Packanging selecionada
  const [selectedCategory, setSelectedCategory] = useState(""); // Categoria selecionada
  const [selectedSupplier, setSelectedSupplier] = useState(""); // Fornecedor selecionado
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(5);
  const [editingProduct, setEditingProduct] = useState(null);
  const [filepath, setFilepath] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      BitromService.findAllCategories(),
      BitromService.findAllSuppliers(),
      BitromService.findAllProducts(currentPage, productsPerPage),
    ]).then(([categoriesResponse, suppliersResponse, productsResponse]) => {
  
      const sortedProducts = productsResponse.products.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
  
      // Atualiza as categorias e fornecedores
      setCategories(categoriesResponse);
      setSuppliers(suppliersResponse);
  
      // Atualiza o estado de produtos acumulando os itens
      setProducts(sortedProducts);
      setFilteredProducts(sortedProducts);
  
      // Atualiza o total de produtos e páginas
      setTotalProducts(productsResponse.totalProducts);
      setTotalPages(productsResponse.totalPages);
  
      setIsLoading(false);
    });
  }, [currentPage]);
  
  // Função para lidar com a edição do produto
const handleEdit = (product) => {
  setEditingProduct(product);
  setId(product._id);
  setCode(product.code);
  setDescription(product.description);
  setSelectedPackaging(product.packaging);
  setPrice(product.price);
  setUnitPrice(product.unitPrice);
  setBarcode(product.barcode);
  setIpi(product.ipi);
  setSelectedCategory(product.category._id);
  setSelectedSupplier(product.supplier._id);
  setQuantityStock(product.quantityStock);
  setFilepath(`${process.env.REACT_APP_BASE_URL_API_GTW}/uploads/${product.imagePath}`); // A URL da imagem renderizada
  setImagePath(product.imagePath);
};

// Função para lidar com a exclusão do produto
const handleDelete = async (product) => {
  if (window.confirm('Tem certeza que deseja excluir este produto?')) {
    // Lógica para excluir o produto (chamada API)
    const responseDelectedImage = await BitromService.deleteImage(product.imagePath);

    if (responseDelectedImage) {
      alert(responseDelectedImage.msg);

      const responseProductDelected = await BitromService.productDelete(product._id);
      console.log('responseProductDelected', responseProductDelected);
      alert(responseProductDelected.msg);

      // Atualizar lista de produtos após exclusão
      const newProducts = products.filter(item => item._id !== product._id);
        // Adiciona o novo produto à lista, mantendo a ordem alfabética
        const updatedProductsPosDelete = [...newProducts].sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        setProducts(updatedProductsPosDelete);
        setFilteredProducts(updatedProductsPosDelete);
    }
        
      error => console.error('Erro ao excluir o produto:', error);
  }
};

    // Função para formatar o valor para o formato R$ 1.000,00
    const handlePriceChange = (e) => {
      let value = e.target.value;
      
      // Remove todos os caracteres que não sejam números
      value = value.replace(/\D/g, "");
  
      // Adiciona os pontos e vírgulas no formato correto
      value = (Number(value) / 100).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
  
      setPrice(value);
    };
    
    const handleUnitPriceChange = (e) => {
      let value = e.target.value;
      
      // Remove todos os caracteres que não sejam números
      value = value.replace(/\D/g, "");
  
      // Adiciona os pontos e vírgulas no formato correto
      value = (Number(value) / 100).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
  
      setUnitPrice(value);
    };

  const handleFileChange = (event) => {
    setImagePath(null);
    const file = event.target.files[0];
    setFile(file); // Atualiza o estado com o arquivo selecionado
  
    // Cria um FileReader para ler o conteúdo da imagem
    const reader = new FileReader();
    
    reader.onload = () => {
      // Quando a leitura for concluída, atualize o estado com a URL da imagem
      setFilepath(reader.result); // A URL da imagem renderizada
    };
    
    // Leia o arquivo como uma URL de dados (data URL)
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        let pathImage = "";
        console.log('file: ', file);
        if (file !== null) {
          const result = await BitromService.uploadImage(file);
          pathImage = `${result.imagePath}`;
        } else {
          pathImage = imagePath;
        }

        // Cria um FormData para os dados do produto, incluindo o `imagePath` retornado
        const productData = {
          id: id || "",
          code: code,
          description: description,
          packaging: selectedPackaging,
          price: price,
          unitPrice: unitPrice,
          barcode: barcode,
          ipi: ipi,
          category: selectedCategory,
          supplier: selectedSupplier,
          quantityStock: quantityStock,
          imagePath: pathImage, // Usa o caminho da imagem salva
      };
      
      console.log('Enviando dados do produto como JSON:', productData);
      
      const productResponse = await BitromService.productSave(productData);

      console.log('productResponse2:', productResponse._id);
      

        if (productResponse) {
            // alert(productResponse.msg);

            // Atualiza a lista de produtos
            const newProducts = products.filter(product => product._id !== productResponse._id);
            const updatedProducts = [...newProducts, productResponse].sort((a, b) =>
                a.description.localeCompare(b.description)
            );
            setProducts(updatedProducts);
            setFilteredProducts(updatedProducts);

            // Reseta os campos do formulário
            setId(null);
            setCode("");
            setDescription("");
            setPrice("");
            setUnitPrice("");
            setSelectedCategory("");
            setSelectedSupplier("");
            setSelectedPackaging("");
            setQuantityStock("");
            setBarcode("");
            setIpi("");
            setFile(null);
            setFilepath(null);
            setEditingProduct(null);

            // Limpa o campo de upload de arquivos
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    } catch (error) {
        console.error("Erro ao cadastrar produto:", error);
        alert("Erro ao cadastrar o produto. Por favor, tente novamente.");
    }
};

  // Função para filtrar os produtos com base no termo de busca
const handleSearch = (event) => {
  const value = event.target.value.toLowerCase();
  setSearchTerm(value);

  const filtered = products.filter(product => {
    // Verificar todos os atributos do produto
    console.log(value)
    return (
      product.code.toLowerCase().includes(value) ||
      product.description.toLowerCase().includes(value) ||
      product.packaging.toLowerCase().includes(value) ||
      product.price.toString().toLowerCase().includes(value) ||
      product.unitPrice.toString().toLowerCase().includes(value) ||
      product.barcode.toLowerCase().includes(value) ||
      product.ipi.toString().toLowerCase().includes(value) ||
      product.category.name.toLowerCase().includes(value) ||
      product.supplier.name.toLowerCase().includes(value) ||
      product.quantityStock.toString().toLowerCase().includes(value)
    );
  });

  setFilteredProducts(filtered);
  setCurrentPage(1);
};

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  
  

  return (
    <BitromContainer>
      {/* Formulário de Cadastro */}
      <h2>{editingProduct ? 'Editar Produto' : 'Cadastro de Produtos'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Código"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <input
          type="text"
          placeholder="Descrição"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {/* Select de Packaging */}
        <select
          value={selectedPackaging}
          onChange={(e) => setSelectedPackaging(e.target.value)}
        >
          <option value="">Selecione um Tipo de Embalagem</option>
          {packagings.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Preço R$ 0,00"
          value={price}
          onChange={handlePriceChange}
        />
        <input
          type="text"
          placeholder="Preço Unitário R$ 0,00"
          value={unitPrice}
          onChange={handleUnitPriceChange}
        />
        <input
          type="text"
          placeholder="Código de barras"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
        />
        <input
          type="number"
          placeholder="%IPI"
          value={ipi}
          onChange={(e) => setIpi(e.target.value)}
        />
        {/* Select de Categorias */}
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Selecione uma Categoria</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
  
        {/* Select de Fornecedores */}
        <select
          value={selectedSupplier}
          onChange={(e) => setSelectedSupplier(e.target.value)}
        >
          <option value="">Selecione um Fornecedor</option>
          {suppliers.map((supplier) => (
            <option key={supplier._id} value={supplier._id}>
              {supplier.name}
            </option>
          ))}
        </select>
        <input
          type="number"
          placeholder="Quantidade em Estoque"
          value={quantityStock}
          onChange={(e) => setQuantityStock(e.target.value)}
        />
        {/* Pré-visualização da Imagem */}
  {/* {filepath && (
    <div>
      <p>Imagem atual:</p>
      <img
        src={`${process.env.REACT_APP_BASE_URL_API_GTW}/uploads/${filepath}`}
        alt="Imagem do produto"
        width="100"
      />
    </div>
  )} */}
  {filepath && (
  <img src={filepath} alt="Preview" style={{ maxWidth: '200px', height: 'auto' }} />
)}

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <button type="submit">{editingProduct ? 'Salvar Alterações' : 'Cadastrar Produto'}</button>
        <hr></hr>
      </form>
  
      {/* Loading */}
      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {/* Campo de Busca */}
          <input
            type="text"
            placeholder="Filtrar produtos por descrição"
            value={searchTerm}
            onChange={handleSearch}
          />
  
          {/* Lista de Produtos Cadastrados */}
          <h2>Lista de Produtos Cadastrados</h2>
          <ul>
            {filteredProducts.length > 0 ? filteredProducts.map((product) => (
              <li key={product._id}>
                <div>
                  <strong>Código:</strong> {product.code} <br />
                  <strong>Descrição:</strong> {product.description} <br />
                  <strong>Categoria:</strong> {product.category.name} <br />
                  <strong>Fornecedor:</strong> {product.supplier.name} <br />
                  <strong>Preço:</strong> {product.price} <br />
                </div>
                <img
                  src={`${process.env.REACT_APP_BASE_URL_API_GTW}/uploads/${product.imagePath}`}
                  alt={product.code}
                  width="100"
                />
                <button onClick={() => handleEdit(product)}>Editar</button>
                <button onClick={() => handleDelete(product)}>Excluir</button>
              </li>
            )) : 
            <li>
                <div>
                  <strong>Sem dados para o filtro especificado!!!</strong><br />
                </div>
              </li>
            }
          </ul>
  
          {/* Paginação */}
          <div className="pagination">
          {/* Botão para ir à primeira página */}
          <button onClick={() => paginate(1)} disabled={currentPage === 1}>
            {'<<'}
          </button>

          {/* Botão para ir à página anterior */}
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            {'<'}
          </button>

          {/* Lógica para mostrar apenas 3 páginas por vez */}
          {Array.from({ length: 3 }, (_, i) => {
            // Calcula os números das páginas vizinhas (anterior, atual, próxima)
            const pageNumber = Math.max(1, currentPage - 1) + i;

            // Garante que os botões exibidos não ultrapassem o total de páginas
            if (pageNumber > totalPages) {
              return null;
            }

            return (
              <button
                key={pageNumber}
                onClick={() => paginate(pageNumber)}
                className={currentPage === pageNumber ? 'active' : ''}
              >
                {pageNumber}
              </button>
            );
          })}

          {/* Botão para ir à próxima página */}
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            {'>'}
          </button>

          {/* Botão para ir à última página */}
          <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>
            {'>>'}
          </button>
        </div>
        </>
      )}
    </BitromContainer>
  );
};

export default ProductForm;
